@use 'styles/mixins/_theme.scss';
@use 'styles/mixins/_typography.scss';

.container {
	position: relative;
	display: inline-block;
}

.button {
	position: relative;
	display: inline-block;
	background-color: transparent;
	text-transform: uppercase;
	&:hover {
		cursor: pointer;
	}
	margin-right: 8px;
	margin-left: 8px;
}

.buttonText {
	display: inline-block;
	text-transform: uppercase;
}

.buttonArrow {
	display: inline-block;
	margin-left: 4px;
}

.dropDown {
	position: absolute;
	display: grid;
	grid-template-columns: 120px;
	grid-template-rows: repeat(2, 36px);
	align-items: center;
	justify-items: center;
	justify-content: center;
	top: 36px;
	margin-left: -50px;
	z-index: 999;
	box-shadow: theme.$box-shadow;
	padding: 0;
	border-radius: 16px;
	background-color: theme.$popup-menu-bgcolor;
}

.dropDownItem {
	display: block;
	text-align: center;
	width: 100%;
	height: 36px;
	line-height: 36px;
	text-transform: uppercase;
	@include typography.no-select();

	color: theme.$popup-menu-color;
	background-color: theme.$popup-menu-bgcolor;

	&:hover {
		color: theme.$popup-menu-hover-color;
		background-color: theme.$popup-menu-hover-bgcolor;
	}

	&:first-child {
		border-top-right-radius: 16px;
		border-top-left-radius: 16px;
	}

	&:last-child {
		border-bottom-right-radius: 16px;
		border-bottom-left-radius: 16px;
	}
}
