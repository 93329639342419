@use 'styles/mixins/_theme.scss';
@use 'styles/mixins/_typography.scss';

$label-color: theme.$text-secondary-light;
$label-color-focus: theme.$primary;
$border-color: theme.$lightgray;
$height: 56px;

@mixin label($element) {
	#{$element} .label {
		padding-top: 0.2rem;
		padding-left: 16px;
		padding-right: 16px;
		@include typography.font-normal(14px);
		line-height: 24px;
		color: $label-color;
		background-color: transparent;
		pointer-events: none;

		&.error {
			color: inherit;
		}
	}
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
	transition: background-color 50000s ease-in-out 0s,
		color 5000s ease-in-out 0s !important;
	background-color: transparent !important;
}
select[data-autocompleted] {
	background-color: transparent !important;
}

@mixin input($element) {
	#{$element} {
		position: relative;
		display: grid;
		grid-template-rows: minmax($height, auto) auto;
		width: 100%;
		background-color: rgba(255, 255, 255);
		color: theme.$text-secondary-light;
		transition: 0.3s all;
	}

	#{$element} > textarea {
		padding-top: 26px;
	}

	/* Firefox */
	#{$element} select,
	input[type='number'] {
		-moz-appearance: textfield;
	}

	#{$element} select,
	input,
	textarea {
		position: relative;
		// padding: 16px 16px 0 20px;
		@include typography.font-normal(16px);
		background-color: transparent;
		color: theme.$text-primary;
		width: calc(100% - 32px);
		margin: 2px 16px 2px 16px;
		padding: 0px !important;
		outline: none;
		border: none !important;
		// box-shadow: 0px 4px 20px 0px transparent;
		// transition: 0.3s background-color ease-in-out,
		// 	0.3s box-shadow ease-in-out, 0.3s padding ease-in-out;
		-webkit-appearance: none;
	}

	.border {
		border-radius: 12px;
		border: 1px solid $border-color;
		width: 100%;

		&:focus-within {
			border: 1px solid $label-color-focus !important;

			.label {
				color: $label-color-focus !important;
			}
		}
	}

	.locked {
		pointer-events: none;
		color: theme.$text-secondary-light;
	}

	#{$element} input::-webkit-date-and-time-value {
		text-align: left;
	}

	#{$element} input[type='date']::-webkit-inner-spin-button,
	#{$element} input[type='date']::-webkit-calendar-picker-indicator {
		margin-right: 16px;
	}

	#{$element} input::-webkit-input-placeholder {
		color: lighten(theme.$text-secondary-light, 25%);
	}
	#{$element} input::-moz-placeholder {
		color: lighten(theme.$text-secondary-light, 25%);
	}
	#{$element} textarea::-webkit-input-placeholder {
		color: lighten(theme.$text-secondary-light, 25%);
	}
	#{$element} textarea::-moz-placeholder {
		color: lighten(theme.$text-secondary-light, 25%);
	}
}
