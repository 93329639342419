@use 'styles/mixins/_theme.scss';
@use 'styles/mixins/_typography.scss';

.container {
	position: relative;
	display: inline-block;
	min-width: 24px;
	text-align: right;
}

.container {
	position: relative;
	background-color: transparent;
	&:hover {
		cursor: pointer;
	}
	margin-right: 8px;
	margin-left: 8px;
}

.buttonDots {
	color: theme.$primary;
	@include typography.no-select();
}

$buttonUserWidth: 160px;

.buttonUser {
	display: grid;
	grid-template-columns: minmax(auto, calc($buttonUserWidth - 16px)) 16px;
	grid-template-rows: auto auto;
	column-gap: 4px;
	row-gap: 4px;
	align-items: center;
	justify-content: left;
	max-width: $buttonUserWidth;
}

.userName {
	justify-self: left;
	grid-row: 1;
	grid-column: 1;
	text-transform: capitalize;
	color: theme.$text-primary;
	@include typography.font-semibold(14px);
	@include typography.no-select();
	text-overflow: ellipsis;
	white-space: pre-wrap;
	overflow: hidden;
	max-width: calc($buttonUserWidth - 16px);
}

.email {
	justify-self: left;
	grid-row: 2;
	grid-column: 1 / span 2;
	text-transform: lowercase;
	color: theme.$text-secondary;
	@include typography.font-medium(12px);
	@include typography.no-select();
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	max-width: $buttonUserWidth;
}

.buttonArrow {
	grid-row: 1;
	grid-column: 2;
}

.dropDown {
	position: absolute;
	display: grid;
	grid-template-columns: auto;
	grid-auto-rows: auto;
	align-items: center;
	justify-items: left;
	justify-content: left;
	top: 48px;
	right: 0px;
	box-shadow: theme.$box-shadow;
	padding: 0px 12px 6px 12px;
	border-radius: 16px;
	color: theme.$popup-menu-color;
	background-color: theme.$popup-menu-bgcolor;
	z-index: 9999;
}

.dropDownItem {
	vertical-align: middle;
	width: 100%;
	padding-top: 16px;
	padding-bottom: 16px;
	text-align: left;
	text-transform: none;
	@include typography.font-medium(14px);
	@include typography.no-select();

	&:not(:last-child) {
		border-bottom: 1px solid #ded8d4;
	}

	color: theme.$popup-menu-color !important;
	background-color: theme.$popup-menu-bgcolor;

	& > span,
	a {
		color: theme.$popup-menu-color !important;
	}

	&:hover {
		color: theme.$popup-menu-hover-color;
		// background-color: theme.$popup-menu-hover-bgcolor;
	}

	&:first-child {
		border-top-right-radius: 16px;
		border-top-left-radius: 16px;
	}

	&:last-child {
		border-bottom-right-radius: 16px;
		border-bottom-left-radius: 16px;
	}
}

.logout {
	color: theme.$error !important;
}

.link {
	text-decoration: none;
	color: theme.$popup-menu-color !important;
}
