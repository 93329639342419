@mixin screen($size) {
	@if $size == 'xs' {
		@media (max-width: 599px) {
			@content;
		}
	} @else if $size == 'md' {
		@media (max-width: 900px) {
			@content;
		}
	} @else if $size == 'md2' {
		@media (max-width: 1280px) {
			@content;
		}
	} @else if $size == 'lg' {
		@media (max-width: 1440px) {
			@content;
		}
	} @else if $size == 'xl' {
		@media (min-width: 1900px) {
			@content;
		}
	} @else {
		@content;
	}
}

@mixin min-screen($size) {
	@if $size == 'xs' {
		@media (min-width: 599px) {
			@content;
		}
	} @else if $size == 'md' {
		@media (min-width: 900px) {
			@content;
		}
	} @else if $size == 'md2' {
		@media (min-width: 1280px) {
			@content;
		}
	} @else if $size == 'lg' {
		@media (min-width: 1440px) {
			@content;
		}
	} @else if $size == 'xl' {
		@media (min-width: 1440px) {
			@content;
		}
	} @else {
		@content;
	}
}

@mixin hscreen($size) {
	@if $size == 'xs' {
		@media (max-height: 750px) {
			@content;
		}
	} @else if $size == 'md' {
		@media (max-height: 925px) {
			@content;
		}
	} @else if $size == 'lg' {
		@media (max-height: 1000px) {
			@content;
		}
	} @else {
		@content;
	}
}
