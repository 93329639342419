@use 'styles/mixins/_theme.scss';
@use 'styles/mixins/_typography.scss';

@font-face {
	font-family: 'Gilroy';
	src: url('~/public/fonts/gilroy/Gilroy-Regular.otf');
	font-style: normal;
	font-weight: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Gilroy-Bold';
	src: url('~/public/fonts/gilroy/Gilroy-Bold.otf');
	font-style: normal;
	font-weight: bold;
	font-display: swap;
}

@font-face {
	font-family: 'Gilroy-SemiBold';
	src: url('~/public/fonts/gilroy/Gilroy-SemiBold.otf');
	font-style: normal;
	font-weight: bold;
	font-display: swap;
}

@font-face {
	font-family: 'Gilroy-Medium';
	src: url('~/public/fonts/gilroy/Gilroy-Medium.otf');
	font-style: normal;
	font-weight: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Architects Daughter';
	src: url('~/public/fonts/ArchitectsDaughter-Regular.ttf');
	font-style: normal;
	font-weight: 400;
	font-display: swap;
}

html,
button,
label,
input,
a,
body {
	font-family: 'Gilroy', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Gilroy', sans-serif;
}

h1 {
	font-size: typography.calculateRem(32px);
	font-style: normal;
	font-weight: 600;
}

h2 {
	font-size: typography.calculateRem(24px);
	font-style: normal;
	font-weight: 600;
}

h3 {
	font-size: typography.calculateRem(18px);
	font-style: normal;
	font-weight: 600;
}

p {
	line-height: 24px;
}
