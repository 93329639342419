@use 'styles/mixins/_theme.scss';
@use 'styles/mixins/_media.scss';
@use 'styles/mixins/_typography.scss';

.content {
	margin: 1px;
}

.container {
	display: grid;
	grid-template-rows: 0.4fr 0.6fr;
	justify-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	padding: 0px;

	@include media.screen(md) {
		grid-template-rows: min-content 1fr;
	}
}

.title {
	grid-row: 1;
	align-self: flex-end;
	@include typography.font-bold(54px);
	color: theme.$primary !important;
	margin-bottom: 84px;

	@include media.screen(md) {
		display: none;
	}
}

.choice2ButtonsTitle {
	@include media.screen(md) {
		display: block;
		@include typography.font-bold(32px);
		align-self: center;
		text-align: center;
		margin-top: 32px;
		margin-bottom: 32px;
		max-width: 240px;
	}
}

.choices {
	grid-row: 2;
	align-self: flex-start;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-columns: repeat(2, 1fr);
	align-items: stretch;
	justify-items: stretch;
	justify-content: stretch;
	margin: 0 10% 0 10%;

	@include media.screen(lg) {
		margin: 0 auto 0 auto;
	}

	@include media.screen(md) {
		grid-row: 1;
		align-self: flex-start;
		grid-template-columns: 1fr;
		grid-template-rows: repeat(3, 1fr);
		margin: 0;
		height: 100%;
	}
}

.choice2Buttons {
	grid-row: 2;
	grid-template-columns: 1fr;
	grid-template-columns: repeat(2, 1fr);

	@include media.screen(md) {
		grid-template-columns: 1fr;
		grid-template-rows: repeat(2, min-content);
	}
}

.choice {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: min-content auto min-content;
	margin: 6%;
	padding: 32px;
	border-radius: 16px;
	cursor: pointer;

	@include media.screen(lg) {
		padding: 16px;
	}

	@include media.screen(md) {
		display: none;
	}

	&:hover {
		color: theme.$white;
		background: theme.$gradient-background;

		.choiceTitle,
		.choiceSubText,
		.choiceAction {
			color: theme.$text-primary-light !important;
		}
	}
}

.choiceMobile {
	display: none;
	grid-template-rows: auto auto;
	grid-template-columns: 0.65fr 0.35fr;
	margin: 6%;
	padding: 24px;
	border-radius: 16px;
	max-height: 140px;
	cursor: pointer;

	@include media.screen(md) {
		display: grid;
	}
}

.choiceTitle {
	grid-row: 1;
	grid-column: 1;
	@include typography.font-bold(32px);
	color: theme.$primary;

	@include media.screen(md) {
		@include typography.font-semibold(20px);
		align-self: flex-end;
	}
}

.choiceSubText {
	grid-row: 2;
	grid-column: 1;
	@include typography.font-medium(16px);

	@include media.screen(md) {
		@include typography.font-medium(14px);
		margin: 8px 0 0 0;
	}
}

.choiceAction {
	grid-row: 3;
	grid-column: 1;
	display: flex;
	@include typography.font-semibold(16px);
	color: theme.$primary;
	align-items: center;
	cursor: pointer;

	.choiceActionIcon {
		margin-left: 8px;
	}

	@include media.screen(md) {
		display: none;
	}
}

.choiceButton {
	grid-row: 1 / span 2;
	grid-column: 2;
	display: flex;
	padding: 8px;

	@include media.screen(md) {
		align-self: center;
		justify-self: center;
	}
}
