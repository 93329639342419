.section {
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: space-evenly;
}

.sectionContainer {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	height: 100%;
	width: 80%;
	max-width: 500px;
	padding: 10vh 0 25vh 0;
}
