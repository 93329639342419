@use 'styles/mixins/_theme.scss';

.layout {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: var(--header-height) auto;
	height: var(--viewport-height);
	/* mobile viewport bug fix */
	min-height: -webkit-fill-available;
	width: 100%;
}

.header {
	flex: 1;
	align-self: stretch;
	justify-self: stretch;
	justify-content: stretch;
}

.content {
	margin: 2vh auto;
}
