@import '../Error.module.scss';
@import '../Input.module.scss';

@include input('.textInput');
@include label('.textInput');
@include error('.textInput');

.date {
	min-width: 200px;
}
