@use 'styles/mixins/_theme.scss';
@use 'styles/mixins/_media.scss';

.card {
	border-radius: 32px;
	padding: 32px 64px;
	background-color: theme.$white;
	box-shadow: 5px 5px 15px 5px theme.$lightsilver;

	@include media.screen(md) {
		padding: 32px;
	}

	@include media.screen(xs) {
		padding: 24px;
	}
}

.blueCard {
	background: linear-gradient(90deg, hsl(219, 98%, 34%), theme.$primary);
}
