@use 'styles/mixins/_theme.scss';

.main {
	--background: #e5f0ff;
	--primary: #003780;
	--text-primary: #171718;
	--text-secondary: theme.$gray-2;
	--text-tertiary: #8e96a3;
	--border: #ded8d4;
	--box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);

	--popup-menu-color: #171718;
	--popup-menu-bgcolor: #f9faf8;
	--popup-menu-hover-color: #f9faf8;
	--popup-menu-hover-bgcolor: #003780;

	--wiseday-logo: #003780;

	a,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: theme.$text-primary;
	}

	color: theme.$text-primary;
	background: theme.$background;
}
