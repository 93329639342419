.layout {
	position: relative;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: var(--header-height) auto;
	height: 100vh;
	width: 100vw;
}

.header {
	flex: 1;
	align-self: stretch;
	justify-self: stretch;
	justify-content: stretch;
}

.content {
	position: relative;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow-y: auto;
}

.loading {
	width: 80px;
}

.fullHeight {
	height: 100%;
}
