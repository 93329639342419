// Colors
$white: #ffffff;
$dark: #171718;
$teal: #7ebcbc;
$green: #46d6a2;
$success: $green;
$warning: #ffdb1f;
$error: #e23b11;
$red: #f24343;
$orangeRed: #ff5530;
$gray: #756b66;
$gray-2: #676869;
$lightgray: #8e96a3;
$lightsilver: #f1f2f3;
$medium-blue: #0048d4;
$blue: hsl(214, 100%, 25%);
$gradient-background-rainbow: linear-gradient(
	277.39deg,
	#e2d7ef 3.96%,
	#e3f0e8 94.54%
);
$gradient-background: linear-gradient(116.82deg, $medium-blue 0%, #18306d 100%);
$glass-teal-gradient-background: linear-gradient(
		0deg,
		rgba(255, 255, 255, 0.4),
		rgba(255, 255, 255, 0.4)
	),
	linear-gradient(0deg, rgba(231, 229, 224, 0.04), rgba(231, 229, 224, 0.04)),
	linear-gradient(
		90deg,
		rgba(138, 218, 203, 0.2) 0%,
		rgba(17, 98, 173, 0.2) 100%
	);

// Colors not affected by theme for now
$text-dark: #171718;

// Global theme vars set tby the theme provider
$background: var(--background);
$primary: var(--primary);
$text-primary: var(--text-primary);
$text-primary-light: $white;
$text-secondary: var(--text-secondary);
$text-secondary-light: $lightgray;
$text-tertiary: var(--text-tertiary);
$border: var(--border);
$box-shadow: var(--box-shadow);

$wiseday-logo: var(--wiseday-logo);

$popup-menu-color: var(--popup-menu-color);
$popup-menu-bgcolor: var(--popup-menu-bgcolor);
$popup-menu-hover-color: var(--popup-menu-hover-color);
$popup-menu-hover-bgcolor: var(--popup-menu-hover-bgcolor);
