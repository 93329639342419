@use 'styles/mixins/_theme.scss';
@use 'styles/mixins/_typography.scss';

.button {
	display: grid;
	grid-template-columns: 90% 10%;
	padding-top: 0px;
	padding-bottom: 0px;
	&:hover {
		cursor: pointer;
	}
}

.buttonText {
	display: inline-block;
	padding-top: 16px;
	padding-bottom: 16px;
}

.buttonArrow {
	display: inline-block;
}

.dropDown {
	margin-bottom: 12px;
}

.dropDownItem {
	display: block;
	text-align: left;
	height: 36px;
	line-height: 36px;
	@include typography.no-select();
}
