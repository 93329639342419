@use 'styles/mixins/_theme.scss';
@use 'styles/mixins/_typography.scss';

.title {
	text-align: center;
	@include typography.font-semibold(24px);
}

.text {
	text-align: center;
	max-width: 350px;
	color: theme.$text-secondary;
	@include typography.font-normal(14px);
}

.button {
	margin-top: 16px;
	width: 100%;
}
