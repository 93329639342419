.expandableContainer {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: repeat(2, auto);
}

.header {
	display: grid;
	grid-template-columns: 1fr 16px;
	cursor: pointer;
}

.headerDisabled {
	cursor: default;
}

.buttonArrow {
	display: inline-block;
	margin: auto;
	grid-row: 1;
	grid-column: 2;
}

.down {
	transform: rotate(0deg);
}

.up {
	transform: rotate(-180deg);
}

.content {
	grid-row: 2;
	grid-column: 1 / span 2;
}

.collapsed {
	overflow: hidden; /* Hide the element content, while height = 0 */
	height: 0;
	opacity: 0;
	transition: height 0ms 500ms, opacity 500ms 0ms;
}
.expanded {
	height: auto;
	opacity: 1;
	transition: height 0ms 500ms, opacity 500ms 0ms;
}
