@mixin error($element) {
	.errorMessage {
		color: #ec392f;
		font-size: 0.875rem;
		margin: 4px 0px 0px 8px;
		white-space: pre-line;
		line-height: 1.5;
	}
	#{$element}.error {
		color: #ec392f;

		.border {
			border: 1px solid #ec392f;
		}

		.currencyDisplay {
			color: #ec392f;
		}
	}
}
