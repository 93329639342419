.container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: right;
}

.starImageContainer {
	overflow: hidden;
}

.starImage {
	top: -1px;
	height: 24px;
	width: 24px;
}
