@use 'styles/mixins/_theme.scss';
@use 'styles/mixins/_media.scss';
@use 'styles/mixins/_typography.scss';

.container {
    padding-top: 3vh;
}

.title {
	@include typography.font-semibold(28px);
}

.text {
	@include typography.font-normal(16px);
	color: theme.$text-secondary;
}

.card {
	max-width: 600px;
	margin: 16px;

	p {
		white-space: pre-line;
	}

	@include media.screen(md) {
		padding: 16px;
	}
}

.fieldset {
	border: none;
	display: grid;
	grid-template-columns: 1fr;
	grid-auto-rows: min-content;
	align-items: stretch;
	justify-items: stretch;
	justify-content: stretch;
	row-gap: 16px;
	width: auto;
	margin-top: 32px;
	padding: 0px;
}

.textarea {
	height: max-content;
}
