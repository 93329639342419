@use 'styles/mixins/_theme.scss';
@use 'styles/mixins/_typography.scss';
@use 'styles/mixins/_media.scss';

.error {
	background-color: lighten(theme.$error, 45%);
	color: theme.$error;
	padding: 24px 48px;
	border-radius: 8px;

	@include typography.font-normal(16px);
	line-height: 24px;

	@include media.screen(xs) {
		padding: 24px 24px;
		@include typography.font-normal(14px);
	}
}

.info {
	display: grid;
	grid-template-columns: min-content auto;
	grid-template-rows: 1fr;
	padding-bottom: 24px;
	align-items: center;
	gap: 16px;
	@include typography.font-normal(16px);
	color: theme.$text-secondary;
	line-height: 24px;

	@include media.screen(xs) {
		grid-template-columns: 0px auto;
		gap: 0px;
		@include typography.font-normal(14px);

		& .icon {
			visibility: hidden;
			gap: 0px;
		}
	}
}

.noIcon {
	grid-template-columns: auto;
}

.success {
	background-color: lighten(theme.$success, 45%);
	display: flex;
	flex-flow: row nowrap;
	padding-bottom: 24px;
	align-items: center;
	gap: 16px;
	@include typography.font-normal(16px);
	color: theme.$text-secondary;
	line-height: 24px;

	@include media.screen(xs) {
		@include typography.font-normal(14px);
	}
}

.icon {
	display: inline;
	min-height: 40px;
	min-width: 40px;
}
