@use 'styles/mixins/_media.scss';
@use 'styles/mixins/_theme.scss';

.dialogBackground {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background: rgba(#ececec, 0.6);
}

.dialog {
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	margin: auto;
	border-radius: 24px;
	max-width: 40rem;
	box-shadow: none;
	display: flex;
	flex-direction: column;
	justify-items: center;
	align-items: center;
	justify-content: center;
	color: theme.$dark;

	@include media.screen(md) {
		max-width: 70%;
	}

	@include media.screen(xs) {
		width: 90%;
	}
}

.closeButton {
	position: absolute;
	top: 24px;
	right: 24px;
	height: 12px;
	width: 12px;
	cursor: pointer;
}
