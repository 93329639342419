@use 'styles/mixins/_theme.scss';
@import '../Error.module.scss';
@import '../Input.module.scss';

@include input('.selectInput');
@include label('.selectInput');
@include error('.selectInput');

.selectInput select {
	/* for Firefox */
	-moz-appearance: none;
	/* for Chrome */
	-webkit-appearance: none;
	height: inherit;
	cursor: pointer;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 2;
	margin-top: 20px !important;
}

.selectInput select::-ms-expand {
	/* For IE10 */
	display: none;
}

.dropDownIcon {
	position: absolute;
	top: calc(50% - 8px);
	right: 16px;
	height: 16px;
	width: 16px;
	color: theme.$text-secondary-light;
}

.placeholder {
	color: lighten(theme.$text-secondary-light, 25%) !important;
}

.error {
	.dropDownIcon {
		color: theme.$error;
	}
}

.option {
	color: theme.$text-primary !important;
	padding: 8px;
}
