@use 'styles/mixins/_theme.scss';
@use 'styles/mixins/_media.scss';
@use 'styles/mixins/_typography.scss';

.button {
	border-radius: 8px;
	border: none;
	box-shadow: none;
	cursor: pointer;
	@include typography.font-semibold(16px);
	text-align: center;
	height: fit-content;
	width: fit-content;

	@include media.screen(md) {
		@include typography.font-semibold(14px);
	}
}

.big {
	padding: 16px 32px;
}

.medium {
	padding: 12px 28px;
}

.small {
	padding: 8px 24px;
}

.primary {
	background-color: theme.$primary;
	color: theme.$white;

	&:focus {
		outline: none;
		box-shadow: 0px 0px 0px 4px #0037801f;
	}

	&:hover {
		background-color: #022e68;
	}

	&.disabled {
		background: theme.$lightsilver;
		background-color: theme.$lightsilver;
		color: theme.$lightgray;
		cursor: default;

		&:hover {
			background-color: theme.$lightsilver;
		}
	}
}

.secondary {
	background-color: theme.$white;
	color: theme.$primary;
	border: 1px solid theme.$primary;

	&:focus {
		outline: none;
		background-color: theme.$lightsilver;
	}

	&:hover {
		background-color: theme.$primary;
		color: theme.$white;
		border-color: #022e68;
	}

	&.disabled {
		background-color: theme.$white;
		color: theme.$lightgray;
		border: 1px solid theme.$lightgray;
		cursor: default;

		&:hover {
			background-color: theme.$white;
		}
	}
}

.busy {
	background-color: theme.$lightsilver;
	color: theme.$lightgray;
	cursor: default;

	&:hover {
		color: theme.$lightgray;
		background-color: theme.$lightsilver;
		border: none;
	}
}

.busyIcon {
	display: inline-block;
	height: fit-content;
	line-height: 12px;
	width: 48px;
}
