@use 'styles/mixins/_theme.scss';
@use 'styles/mixins/_media.scss';
@use 'styles/mixins/_typography.scss';

.header {
	display: flex;
	justify-content: center;
	height: 100%;
	width: 100%;
	background-color: transparent;
	border-bottom: 1px solid theme.$border;
	z-index: 1;
}

.toolbarContainer {
	display: flex;
	flex: 1;
	flex-flow: row nowrap;
	align-items: center;
	justify-items: center;
	justify-content: space-between;
	margin-left: 16px;
	margin-right: 16px;
	max-width: min(90vw, 1440px);
}

.rightMenu {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-items: center;

	& > .menu {
		margin: 8px;
	}
}

.menuItem {
	align-items: center;
	justify-items: center;
	text-transform: capitalize;
	margin: 0 16px;
	@include typography.font-normal(16px);
	color: theme.$text-primary;

	@include media.screen(xs) {
		margin: 0 8px;
	}
}

.languageMenu {
	@include media.screen(xs) {
		display: none;
	}
}

.starRating {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: repeat(2, min-content);
}

.starRatingDesktop {
	display: flex;

	@include media.screen(xs) {
		display: none !important;
	}
}

.starRatingMobile {
	display: none !important;
	padding-right: 0px !important;

	@include media.screen(xs) {
		display: flex !important;
	}
}

.starRatingText {
	@include typography.font-semibold(12px);
	color: theme.$text-secondary;
	white-space: nowrap;
	text-align: center;
	margin-top: 6px;

	@include media.screen(xs) {
		@include typography.font-semibold(10px);
		color: theme.$text-primary;
		white-space: nowrap;
		margin-top: 0px;
	}
}

.googleLogo {
	display: inline-block;
	height: 12px;
	width: auto;
	margin: auto auto -3px auto;
}
