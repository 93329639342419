@use 'styles/mixins/_theme.scss';

.main {
	--background: linear-gradient(90deg, hsl(219, 98%, 34%), theme.$primary);
	--primary: hsl(214, 100%, 25%);
	--text-primary: #f9faf8;
	--text-secondary: hsl(222, 15%, 49%);
	--text-tertiary: #8e96a3;
	--border: hsla(0, 0%, 100%, 0.1);
	--box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);

	--popup-menu-color: #003780;
	--popup-menu-bgcolor: #f9faf8;
	--popup-menu-hover-color: #003780;
	--popup-menu-hover-bgcolor: #efefef;

	--wiseday-logo: #f9faf8;

	a,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: theme.$text-primary;
	}

	color: theme.$text-primary;
	background: linear-gradient(
		90deg,
		hsl(219, 98%, 34%),
		theme.$primary
	); // hsl must specify it here to work
}
