@use 'styles/mixins/_media.scss';
@use 'styles/mixins/_theme.scss';
@use 'styles/mixins/_typography.scss';

.container {
	position: fixed;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 10;

	background-color: theme.$white;
	border-top: 1px solid theme.$border;
}

.image {
	height: 42px;
	width: 42px;

	@include media.screen(md) {
		display: none;
	}
}

.content {
	display: grid;
	grid-template-columns: 0.2fr 1.5fr 0.8fr;
	grid-template-rows: 1fr;
	align-items: center;
	margin: 12px 10%;

	@include media.screen(lg) {
		margin: 12px 32px;
	}

	@include media.screen(md) {
		grid-template-columns: 1fr;
		margin: 12px 0px;
	}
}

.text {
	margin: auto 32px;

	@include typography.font-normal(14px);
	color: theme.$text-secondary;
	justify-content: left;

	@include media.screen(lg) {
		white-space: unset;
	}

	@include media.screen(md) {
		margin: auto 16px auto 16px;
		line-height: 130%;
	}

	.link {
		color: theme.$text-secondary;
		text-decoration: underline;
	}
}

.buttons {
	display: grid;
	grid-template-columns: repeat(2, minmax(min-content, 140px));
	grid-template-rows: 1fr;
	align-items: center;
	justify-content: center;

	@include media.screen(md) {
		grid-template-columns: 1.4fr 0.6fr;
		margin: 12px 16px 0px 16px;
	}

	.ctaAccept {
		display: flex;
		flex-direction: row;
		align-items: center;

		@include typography.font-normal(16px);
		border-color: theme.$medium-blue;
		background-color: theme.$medium-blue;

		.greenCheck {
			margin-left: 16px;
			height: 20px;
			width: 20px;

			@include media.screen(md) {
				margin-left: 8px;
				height: 16px;
				width: 16px;
			}
		}

		&:hover {
			text-decoration: underline;
		}

		@include media.screen(md) {
			@include typography.font-normal(14px);
			justify-self: left;
		}
	}

	.ctaReject {
		border: none !important;

		@include typography.font-normal(16px);
		color: theme.$text-secondary;
		text-decoration: underline;
		align-items: center;

		&:hover {
			color: theme.$text-primary;
			border-color: transparent;
			background-color: transparent;
		}

		@include media.screen(md) {
			@include typography.font-normal(14px);
			justify-self: right;
		}
	}
}
