@use './mixins/_theme.scss';
@use './mixins/_media.scss';
@import 'normalize.scss';
@import 'typography.scss';

:root {
	--viewport-height: 100vh;
	--header-height: clamp(56px, 8vh, 96px);
}

html,
body {
	font-size: 16px;
}
