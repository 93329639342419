@function calculateRem($size) {
	$remSize: calc($size / 16px);
	@return $remSize * 1rem;
}

@mixin font-normal($size) {
	font-size: calculateRem($size);
	font-family: 'Gilroy', sans-serif;
	font-weight: 400;
	white-space: pre-line;
}

@mixin font-medium($size) {
	font-size: calculateRem($size);
	font-family: 'Gilroy-Medium', sans-serif;
	font-style: normal;
	white-space: pre-line;
}

@mixin font-semibold($size) {
	font-size: calculateRem($size);
	font-family: 'Gilroy-SemiBold', sans-serif;
	white-space: pre-line;
}

@mixin font-bold($size) {
	font-size: calculateRem($size);
	font-family: 'Gilroy-Bold', sans-serif;
	white-space: pre-line;
}

@mixin font-cursive-normal($size) {
	font-size: calculateRem($size);
	font-family: 'Architects Daughter', cursive;
	font-weight: 400;
	white-space: pre-line;
}

@mixin underline($color) {
	position: relative;
	display: inline;
	width: 100%;
	z-index: 0;
	white-space: nowrap;
	background-color: transparent;

	&::after {
		display: inline-block;
		position: absolute;
		bottom: 0.15em;
		left: 0;
		content: '';
		height: 0.2em;
		width: inherit;
		z-index: -1;
		background-color: $color;
	}
}

@mixin no-select() {
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
}

@mixin ellipsis() {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
