@use 'styles/mixins/_theme.scss';
@use 'styles/mixins/_media.scss';
@use 'styles/mixins/_typography.scss';

.liveSupport {
	display: grid;
	grid-template-columns: 16px 1fr;
	grid-template-rows: auto auto;
	column-gap: 8px;
	row-gap: 4px;
	justify-items: center;
}

.noIcon {
	grid-template-columns: 1fr;
	justify-items: left;

	.title {
		grid-row: 1;
		grid-column: 1;
	}

	.phoneNumber {
		grid-row: 2;
		grid-column: 1;
	}
}

.icon {
	grid-row: 1 / span 2;
	grid-column: 1;
	height: 16px;
	width: 16px;
}

.title {
	grid-row: 1;
	grid-column: 2;
	@include typography.font-medium(16px);
	color: theme.$text-primary;
	white-space: nowrap;
}

.phoneNumber {
	grid-row: 2;
	grid-column: 2;
	@include typography.font-normal(14px);
	opacity: 0.5;
	justify-self: center;

	@include media.screen(md) {
		justify-self: right;
	}
}

.phoneNumberLeft {
	justify-self: left !important;
}
