@use 'styles/mixins/_theme.scss';

.toast {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	z-index: 1000;
}

.content {
	position: relative;
	margin: 32px;
	opacity: 0.95;
}

.closeButton {
	position: absolute;
	top: 8px;
	right: 8px;
	height: 16px;
	width: 16px;
	color: #00000030;
	cursor: pointer;
}
